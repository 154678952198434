App {
    text-align: center;
 }
 form {
   /* border:2px solid green; */
   padding: 50px;
 }
 /* img{
   height: 120px;
   margin-left: 90px;
   margin-bottom: 10px;
   display: block;
   border:1px solid black;
   border-radius: 50%;
 } */
 .App-header {
   background-color: white;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: black;
 }