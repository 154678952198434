*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --light-white: #fafafa;
  --black: #000000;
  --gray: #E5E5E5;
  --gray-two: #A8A8A8;
  --blue: #175CAC;
  --blue-one: #2B3F95;
  --blue-two: #4557A2;
  --primery-font: 'Poppins', sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background: var(--gray);
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: var(--primery-font);
  color: var(--black);
}

img {
  max-width: 100%;
}

a {
  outline: none;
}

.main_wrapper {
  background: var(--light-white);
  max-width: 37.5rem;
  margin: 0 auto;
}

header {
  padding: 3rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider_wrapper {
  padding: 0;
}

.slider_card {
  margin: 15px;
  max-width: 34rem;
  background: var(--white);
  border-radius: 3.4rem;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.15);
}

.image_block {
  margin: 0 0 2rem 0;
}

.price_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.price_wrapper .block:not(:last-child) {
  margin: 0 2rem 0 0;
}

.price_wrapper .block {
  border-radius: 3rem;
  border: 0.1rem solid var(--gray-two);
  flex: 0 0 12rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
}

.paybutton {
  background: var(--blue);
  width: 16.4rem;
  margin: 4rem auto 2rem;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  text-decoration: none;
  height: 6.4rem;
}

footer {
  background-image: linear-gradient(86.42deg, var(--blue-one) 28.07%, var(--blue-two) 88.91%);
  padding: 1rem 0 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.owl-carousel .owl-stage-outer {
  padding: 1.8rem;
}

.owl-theme .owl-nav {
  margin: 0 !important;
  position: relative;
}

.owl-theme .owl-nav [class*=owl-] {
  background: var(--white) !important;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 10rem !important;
  border: 0.1rem solid var(--blue) !important;
  font-size: 0 !important;
  position: absolute;
  top: -25rem;
}

.owl-prev {
  left: 0;
}

.owl-next {
  right: 0;
}

.owl-theme .owl-nav .disabled {
  opacity: .3 !important;
}

.owl-theme .owl-nav button {
  position: relative;
}

.owl-prev::before {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  border-top: 0.3rem solid var(--blue-one);
  border-right: 0.3rem solid var(--blue-one);
  content: "";
  transform: rotate(-136deg);
  top: 1.5rem;
  left: 1.6rem;
}

.owl-next::before {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  border-top: 0.3rem solid var(--blue-one);
  border-right: 0.3rem solid var(--blue-one);
  content: "";
  transform: rotate(47deg);
  top: 1.5rem;
  left: 1.4rem;
}




.opacity-30 {
  opacity: 0.3;
}
.p-3 {
  padding: 0.75rem;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.flex {
  display: flex;
}
.z-20 {
  z-index: 20;
}
.right-0 {
  right: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.top-0 {
  top: 0px;
}
.absolute {
  position: absolute;
}
.carousel * {
  box-sizing: border-box;
}
.opacity-30 {
  opacity: 0.3;
}
.p-3 {
  padding: 0.75rem;
}
.hidden {
  display: none;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.w-9 {
  width: 5.25rem;
}
.h-9 {
  height: 5.25rem;
}
.blueColor{
  color: #040978;
}
.active{
  background: #B5B8D9;
}
.newCls{
  background: var(--white) !important;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 10rem !important;
    border: 0.1rem solid var(--blue) !important;
    font-size: 0 !important;
    position: absolute;
}
.owlArrow{
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  border-top: 0.3rem solid var(--blue-one);
  border-right: 0.3rem solid var(--blue-one);
  content: "";
  transform: rotate(-136deg);
  top: 1.5rem;
  left: 1.6rem;
}
.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading div {
  position: absolute;
  background: #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.margin40{
  margin:40px;
}